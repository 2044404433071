import React from 'react';
import Head from 'next/head';
import { env, app, analytics } from 'config/env';
import { useTranslation } from 'lib/i18n';

const url = `http${env.production ? 's' : ''}://${app.host}`;

const PageHeader = () => {
  const { t } = useTranslation('meta');

  return (
    <Head>
      <title>{t('meta.title')}</title>
      <meta name="title" content={t('meta.title')} />
      <meta name="description" content={t('meta.description')} />
      <meta property="og:title" content={t('meta.title')} />
      <meta property="og:description" content={t('meta.description')} />
      <meta property="og:image" content={`${url}/static/images/share.png`} />
      <meta property="og:image:alt" content={t('meta.sitename')} />
      <meta property="og:image:width" content={t('meta.shareImage.width')} />
      <meta property="og:image:height" content={t('meta.shareImage.height')} />
      <meta property="og:site_name" content={t('meta.sitename')} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={t('meta.type')} />
      <meta property="fb:app_id" content={analytics.facebookId} />
      <meta name="twitter:card" content={t('meta.twitter.type')} />
      <meta name="twitter:image:alt" content={t('meta.sitename')} />
      <meta name="twitter:site" content={t('meta.twitter.name')} />
    </Head>
  );
};

export default PageHeader;
